.custom-file-button {
    input[type="file"] {
        margin-left: -2px !important;

        &::-webkit-file-upload-button {
            display: none;
        }
        &::file-selector-button {
            display: none;
        }
    }

    &:hover {
        label {
            background-color: #dde0e3;
            cursor: pointer;
        }
    }
}
