#training {

    .card-item {
        -webkit-transition: transform .2s;
        -moz-transition: transform .2s;
        -o-transition: transform .2s;
        transition: transform .2s;

        &:hover {
            -webkit-transform: scale(1.1);
            -moz-transform: scale(1.1);
            -o-transform: scale(1.1);
            transform: scale(1.1);
        }
    }

    .bg-primary{
        background-color: $bg-blue !important;
    }

}
